define('modules/process/slidecard/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "swiper.jquery.min", "jquery.livequery"], function (module, config, $, util, templates) {
    return new module({
        name: 'process.slidecard',
        selectors: '.processSlidecardWrap',
        remoteData: [(config.urls.process)],

        config: {},

        routes: [{
            route: 'home',
            extra: true,
            delay: 2000,
            fn: 'initSwiper' // Route fallback method, will typically be handled via setInterval on onRender method
        }],

        initialize: function (data) {
            this.data = data;
            this.renderTemplates(this.data);
        },

        onRender: function () {
            util.setVisibleInterval($(this.getSelectors()), $.proxy(this.initSwiper, this), 1000);  // Swiper will not properly initialize until the element is actually visible on DOM, hence this interval
        },

        methods: {

            renderTemplates: function (data) {
                $(this.getSelectors()).append(templates.processslidecard({
                    steps: data.sort(function (a, b) {
                        return a.id - b.id;
                    })
                }));
            },

            initSwiper: function () {

                function incrementNum() {
                    swiperNum.text('0' + (instance.activeIndex + 1));
                    swiperBg.width((100 / slidesLength * (instance.activeIndex + 1)) + '%');
                }

                if (this.swiperInstance) {
                    return;
                }

                var swiper = $('.process .swiperMain'),
                    swiperNum = $('.processSwiperNavigateNum', swiper),
                    swiperBg = $('.processSwiperNavigateBg', swiper),
                    slidesLength = 0, instance;

                this.swiperInstance = new Swiper($('.swiper-container', swiper)[0], {
                    slidesPerView: 1,
                    speed: 1000,
                    effect: 'coverflow',
                    spaceBetween: 0,
                    loop: false,
                    //next 4 lines remove the touch functionality and the mousedrag (simulating touch)
                    simulatesTouch: false,
                    shortSwipes: false,
                    longSwipes: false,
                    onlyExternal: true,
                    prevButton: $('.processSwiperNavigatePrev', swiper)[0],
                    nextButton: $('.processSwiperNavigateNext', swiper)[0],
                    // pagination
                    pagination: false,
                    preventClicks: false,
                    preventClicksPropagation: false,
                    breakpoints: {
                        768: {
                            simulatesTouch: true,
                            shortSwipes: true,
                            longSwipes: true,
                            onlyExternal: false
                        }
                    }
                });

                instance = this.swiperInstance;
                slidesLength = +instance.slides.length;
                incrementNum();
                instance.on('slideChangeStart', $.proxy(incrementNum, this));
            }
        }
    });
});

