define('templates',['handlebars'], function(Handlebars) {

var glob = ('undefined' === typeof window) ? global : window,

Handlebars = glob.Handlebars || require('handlebars');

this["JST"] = this["JST"] || {};

Handlebars.registerPartial("judgebox", this["JST"]["judgebox"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<a class=\"judgeBox\" data-judge=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"judgeThumb layout-vertical-center\">\n        <img class=\"judgeThumbImg img-responsive\" src=\""
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" border=\"0\">\n        <div class=\"judgeThumbTitle\">\n            <h5>"
    + alias4((helpers.formatName || (depth0 && depth0.formatName) || alias2).call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"formatName","hash":{},"data":data}))
    + "</h5>\n            <h6 class=\"title\">"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</h5>\n        </div>\n    </div>\n</a> ";
},"useData":true}));

Handlebars.registerPartial("judgegroup", this["JST"]["judgegroup"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgebox,depth0,{"name":"judgebox","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<section class=\"judgeGroup "
    + alias4(((helper = (helper = helpers.cms || (depth0 != null ? depth0.cms : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cms","hash":{},"data":data}) : helper)))
    + "\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" data-title=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"judgeGroupHeading\">\n        <h4 class=\"judgeHeadingTitle retired-load_"
    + alias4(((helper = (helper = helpers.cms || (depth0 != null ? depth0.cms : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cms","hash":{},"data":data}) : helper)))
    + "_h3\">"
    + alias4(((helper = (helper = helpers.descTitle || (depth0 != null ? depth0.descTitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"descTitle","hash":{},"data":data}) : helper)))
    + "</h4>\n\n        <div class=\"judgeGroupDescription retired-load_"
    + alias4(((helper = (helper = helpers.cms || (depth0 != null ? depth0.cms : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cms","hash":{},"data":data}) : helper)))
    + "_content\">\n            "
    + ((stack1 = ((helper = (helper = helpers.desc || (depth0 != null ? depth0.desc : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"desc","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n\n        <div class=\"judgeGridContainer\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</section>";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("judgeinfo", this["JST"]["judgeinfo"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                <div class=\"judgeQuoteContainer\"><q>"
    + container.escapeExpression(((helper = (helper = helpers.quote || (depth0 != null ? depth0.quote : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"quote","hash":{},"data":data}) : helper)))
    + "</q></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"judgeInfoContainer border-box\" data-judge=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-pagenav=\"true\">\n    <div class=\"judgeInfoImg\">\n        <img src=\""
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + "\" class=\"img-responsive\" alt=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\"/>\n    </div>\n    <div class=\"judgeInfoText\">\n        <div class=\"judgeHeadingArea\">\n            <span class=\"judgeName\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</span>\n            <span class=\"judgeShortDescription\">"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</span>\n        </div>\n        <div class=\"judgeLongDescription\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.quote : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            "
    + ((stack1 = ((helper = (helper = helpers.longDescription || (depth0 != null ? depth0.longDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"longDescription","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n    </div>\n</div>\n";
},"useData":true}));

Handlebars.registerPartial("judgeinfogroup", this["JST"]["judgeinfogroup"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgeinfo,depth0,{"name":"judgeinfo","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"judgeDetailContainer container\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"judgeInfoGroup row\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n\n        <div class=\"col-md-12\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\"judgeDetailClose\">\n            <i class=\"judgeDetailCloseInner materialIcons\">close</i>\n        </div>\n        <span class=\"judgeDetailNav prev\">\n          <span class=\"judgeDetailNavInner materialIcons\">navigate_before</span>\n        </span>\n        <span class=\"judgeDetailNav next\">\n          <span class=\"judgeDetailNavInner materialIcons\">navigate_next</span>\n        </span>\n        <div class=\"judgeDetailCounter\">\n            <div class=\"currentCount\">1</div>\n            <span class=\"divider\">of</span>\n            <div class=\"totalCount\">"
    + alias4(container.lambda(((stack1 = (depth0 != null ? depth0.records : depth0)) != null ? stack1.length : stack1), depth0))
    + "</div>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("newslistingentry", this["JST"]["newslistingentry"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<a class=\"newsListingEntry\" data-category=\""
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "\" data-news=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" href=\"#news/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <span class=\"newsListingSpace\">\n        <span class=\"row\">\n            <span class=\"col-sm-12 col-md-2\">\n                <span href=\"#news/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"newsListingImageWrap\">\n                    <img src=\""
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + "\" class=\"listingImage\" alt=\""
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\"/>\n                </span>\n            </span>\n            <span class=\"col-md-10\">\n                <span class=\"newsListingContent\">\n                    <h4 class=\"newsListingTitle\">"
    + alias4((helpers.truncate || (depth0 && depth0.truncate) || alias2).call(alias1,(depth0 != null ? depth0.title : depth0),45,{"name":"truncate","hash":{},"data":data}))
    + "</h4>\n                    <span class=\"newsListingDateDescriptionContainer\">\n                        <span class=\"newsListingDate\">"
    + alias4(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data}) : helper)))
    + "</span>\n                        <span class=\"newsListingDescription\">"
    + ((stack1 = (helpers.truncate || (depth0 && depth0.truncate) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),95,{"name":"truncate","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n                    </span>\n                </span>\n            </span>\n        </span>\n    </span>\n</a>";
},"useData":true}));

Handlebars.registerPartial("newspromobox", this["JST"]["newspromobox"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"newsGridPromoBox\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ");\" data-news=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"></div>\n<div class=\"newsBoxInfo\">\n	<span class=\"newsTypeTag\">"
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</span></span><span class=\"newsDate\">"
    + alias4(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data}) : helper)))
    + "</span>\n	<h4>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h4>\n	<h5>"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h5>\n</div>";
},"useData":true}));

Handlebars.registerPartial("newspromocarousel", this["JST"]["newspromocarousel"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <article class=\"item newsPromoCarouselItem"
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || alias2).call(alias1,(data && data.index),"==",0,{"name":"ifComp","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" style=\"background-image: url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ")\">\n                <div class=\"container\">\n                    <div class=\"row layout-vertical-center\">\n                        <div class=\"col-sm-12\">\n                            <div class=\"promoCarouselDetails text-center\">\n                                <span class=\"promoCarouselDate\">"
    + alias4(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data}) : helper)))
    + "</span>\n                                <h3 class=\"promoCarouselTitle hidden-sm hidden-xs\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n                                <h3 class=\"promoCarouselTitle visible-sm visible-xs\">"
    + ((stack1 = (helpers.truncate || (depth0 && depth0.truncate) || alias2).call(alias1,(depth0 != null ? depth0.title : depth0),43,{"name":"truncate","hash":{},"data":data})) != null ? stack1 : "")
    + "</h3>\n                                <span class=\"promoCarouselMore\"><a href=\"#news/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">READ MORE<i class=\"material-icons\">keyboard_arrow_right</i></a></span>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </article>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " active";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                <li class=\"promoCarouselDotsLink"
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || alias2).call(alias1,(data && data.index),"==",0,{"name":"ifComp","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-target=\"#newsPromoCarousel\" data-slide-to=\""
    + alias3(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3((helpers.formatNumber || (depth0 && depth0.formatNumber) || alias2).call(alias1,(helpers.plusOne || (depth0 && depth0.plusOne) || alias2).call(alias1,(data && data.index),{"name":"plusOne","hash":{},"data":data}),{"name":"formatNumber","hash":{},"data":data}))
    + "</li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"newsPromoCarousel\" class=\"carousel slide newsPromoCarousel\" data-ride=\"carousel\">\n\n    <div class=\"carousel-inner newsPromoCarouselInner\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.promoNewsItems : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"newsPromoCarouselNavigationContainer\">\n        <a class=\"left carousel-control newsPromoCarouselArrow\" href=\"#newsPromoCarousel\" data-slide=\"prev\"></a>\n\n        <ol class=\"carousel-indicators newsPromoCarouselDots\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.promoNewsItems : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ol>\n        <span class=\"newsPromoCarouselTotal\">\n        <span class=\"newsPromoCarouselTotalOf\">of</span> "
    + container.escapeExpression((helpers.formatNumber || (depth0 && depth0.formatNumber) || helpers.helperMissing).call(alias1,((stack1 = (depth0 != null ? depth0.promoNewsItems : depth0)) != null ? stack1.length : stack1),{"name":"formatNumber","hash":{},"data":data}))
    + "\n    </span>\n\n        <a class=\"right carousel-control newsPromoCarouselArrow\" href=\"#newsPromoCarousel\" data-slide=\"next\"></a>\n    </div>\n\n\n</div>\n";
},"useData":true}));

Handlebars.registerPartial("scoringintro", this["JST"]["scoringintro"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return " <div class=\"section pushSection layout-auto-height angleBottomRight\" data-bgimage=\"scoring-intro\">\n	<div class=\"introSection container\" data-title=\"\">\n		<!--<div class=\"load_scoring\">-->\n		<div class=\"row center-height\">\n			<div class=\"col-md-10 col-md-offset-1 content load_scoring_card1\">\n				<h1>Scoring Process</h1>\n				<h2>A careful selection of judges grouped into two categories; the Evaluation Panel and the Selection Committee.</h2>\n				<a class=\"intro-section-btn\" data-scrollto=\".scoreQuestion.section\">Interact with the Rubric<i class=\"icon-icon-arrow-down-rounded\"></i></a>		\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true}));

Handlebars.registerPartial("scoringitems", this["JST"]["scoringitems"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "						<div class=\"tick tickTop\" style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\"></div>\n						<div class=\"tick tickBottom\" style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\">"
    + alias3(((helper = (helper = helpers.score || (depth0 != null ? depth0.score : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"score","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"scoreQuestion section pushSection solid-gray-bg\" data-title=\"\" data-bgimage=\"scoring-body\">\n	<div class=\"container\">\n		<div class=\"row center-height\">\n			<a class=\"sbtn scoreCommentBtn primaryBlankBtn hidden-xs\">Leave Comments</a>\n			<h3 class=\"hidden-xs\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + " <span class=\"scoreRange\">(0-"
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + ")</span></h3>\n			<div class=\"text-center\">\n				<div class=\"helpText\"><div class=\"inner\"><h5>Help</h5>"
    + alias4(((helper = (helper = helpers.helpText || (depth0 != null ? depth0.helpText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"helpText","hash":{},"data":data}) : helper)))
    + "</div></div>\n				<div class=\"commentsText text-center\">\n					<textarea placeholder=\"Comments (minimum 50 characters): Explain your score and provide feedback to the participant.\"></textarea>\n					<div class=\"hidden-xs\">\n						<a class=\"btn\" href=\"#\">Submit Comments</a>\n					</div>\n				</div>\n			</div>\n			<div class=\"scoreHeader\">\n				<div class=\"scoreCounter hidden-xs\">\n					<span class=\"current\">"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "</span>\n					<span class=\"total\">"
    + alias4(((helper = (helper = helpers.total || (depth0 != null ? depth0.total : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"total","hash":{},"data":data}) : helper)))
    + "</span>\n				</div>\n				<div class=\"scoreCounter scoreNav\">\n					<span class=\"prev\"><i class=\"icon-arrow-left-full\"></i></span>\n					<span class=\"next\"><i class=\"icon-arrow-right-full\"></i></span>\n				</div>\n				<div class=\"scoreQuestion\">\n					<span class=\"visible-xs\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span>\n					<span class=\"questionText\">"
    + alias4(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper)))
    + "</span>\n					<span class=\"helpQuestion\">\n						<div class=\"icon-icon-help1\"></div>\n					</span>\n					<div class=\"icon-icon-close\"></div>\n				</div>\n			</div>\n			<div class=\"scoreDescription\">\n				<span></span>\n			</div>\n			<div class=\"scoreValue\">\n				<span class=\"valfollow\"></span>\n				<span class=\"valBorderLeft hidden-xs\"></span>\n				<span class=\"valBorderRight hidden-xs\"></span>\n			</div>\n			<div class=\"scoreSliderContainer\">\n				<div class=\"sliderTicks\">\n			    	<span class=\"visible-xs anchorLow\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</span>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			    	<span class=\"visible-xs anchorHigh\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</span>\n				</div>\n				<span class=\"traitEndL\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</span>\n				<div class=\"scoreSlider\">\n					<input id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" class=\"scoreSliderInput\" data-slider-id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "Slider\" type=\"text\" data-slider-min=\"0\" data-slider-max=\""
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + "\" data-slider-step=\"0.1\" data-slider-value=\""
    + alias4(((helper = (helper = helpers.initScore || (depth0 != null ? depth0.initScore : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"initScore","hash":{},"data":data}) : helper)))
    + "\"/>\n				</div>\n				<span class=\"traitEndR\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</span>\n			</div>\n\n		</div>\n		<!--<a class=\"btn scoreCommentBtn primaryBlankBtn hidden-xs hidden-sm \">Leave Comments</a>-->\n	</div>\n</div>";
},"useData":true,"useDepths":true}));

Handlebars.registerPartial("scoringlastcard", this["JST"]["scoringlastcard"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"lastCard section pushSection register layout-auto-height angleTopLeft\" data-bgimage=\"endcard\">\n\n</div>\n";
},"useData":true}));

Handlebars.registerPartial("sidebarbottommenu", this["JST"]["sidebarbottommenu"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "			"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\n				<li><span>"
    + container.escapeExpression(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "</span></li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<a href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"link","hash":{},"data":data}) : helper)))
    + "\">";
},"4":function(container,depth0,helpers,partials,data) {
    return "<div class=\"sidebar-inner-link\">";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.sidebarbottommenu,depth0,{"name":"sidebarbottommenu","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"10":function(container,depth0,helpers,partials,data) {
    return "</div>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"sidebar-inner-list inner-closed\">\n	<span class=\"sidebar-inner-back\">\n		<div class=\"sidebar-icon pe-7s-left-arrow\"></div>\n		<div class=\"sidebar-label\">Back</div>\n	</span>\n	<ul>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</ul>\n</div>";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("sidebaritem", this["JST"]["sidebaritem"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "a";
},"3":function(container,depth0,helpers,partials,data) {
    return "div";
},"5":function(container,depth0,helpers,partials,data) {
    return " sidebarItemSelected";
},"7":function(container,depth0,helpers,partials,data) {
    return " sidebarItemNestedParent";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return "href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"link","hash":{},"data":data}) : helper)))
    + "\"";
},"11":function(container,depth0,helpers,partials,data) {
    return "target=\"_blank\"";
},"13":function(container,depth0,helpers,partials,data) {
    return "sidebar-neverwrap";
},"15":function(container,depth0,helpers,partials,data) {
    var helper;

  return "		<div class=\"sidebar-item-data data-"
    + container.escapeExpression(((helper = (helper = helpers.dataName || (depth0 != null ? depth0.dataName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"dataName","hash":{},"data":data}) : helper)))
    + "\"></div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "materialIcons";
},"19":function(container,depth0,helpers,partials,data) {
    return "keyboard_arrow_down";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.sidebartopmenu,depth0,{"name":"sidebartopmenu","data":data,"indent":"\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + " class=\"sidebarItem"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isSelected : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.newtab : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n	<div class=\"sidebarItemLabel "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.dataName : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n		"
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.dataName : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <div class=\"sidebar-icon "
    + alias4(((helper = (helper = helpers.iconClass || (depth0 != null ? depth0.iconClass : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconClass","hash":{},"data":data}) : helper)))
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        </div>\n	</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ">";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("sidebartopmenu", this["JST"]["sidebartopmenu"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "		"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\n      <div class=\"sidebarItemNestedLabel\">\n        "
    + container.escapeExpression(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\n      </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<a href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"link","hash":{},"data":data}) : helper)))
    + "\" class=\"sidebarItem sidebarItemNested\">";
},"4":function(container,depth0,helpers,partials,data) {
    return "<div class=\"sidebar-inner-link\">";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.sidebarbottommenu,depth0,{"name":"sidebarbottommenu","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"10":function(container,depth0,helpers,partials,data) {
    return "</div>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"sidebarItemNestedContainer\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("winnerslistingentry", this["JST"]["winnerslistingentry"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<section class=\"winnersListingEntryRow col-xs-12\" data-category=\""
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "\" data-winners=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"winnersListingEntrySpace\">\n        <figure class=\"winnersListingEntryImage\">\n            <a href=\""
    + alias4(((helper = (helper = helpers.video || (depth0 != null ? depth0.video : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"video","hash":{},"data":data}) : helper)))
    + "\" class=\"winnersListingEntryLink js-youtube\">\n                <img src=\""
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\"/>\n            </a>\n        </figure>\n        <div class=\"winnersListingEntryContent\">\n            <h4 class=\"winnersListingEntryTeam\">"
    + alias4(((helper = (helper = helpers.team || (depth0 != null ? depth0.team : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"team","hash":{},"data":data}) : helper)))
    + "</h4>\n            <p class=\"winnersListingEntryName\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</p>\n            <p class=\"winnersListingEntryTitle\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</p>\n            <div class=\"winnersListingEntryDescription\">"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n            <a href=\"#winner/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"btnPrimary btnPrimaryAlternate winnersListingEntryMore\">Learn More</a>\n            <a href=\""
    + alias4(((helper = (helper = helpers.video || (depth0 != null ? depth0.video : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"video","hash":{},"data":data}) : helper)))
    + "\" class=\"winnersListingEntryVideo js-youtube\">Watch the Video</a>\n        </div>\n    </div>\n    <div class=\"winnersListingEntryPlayer\">\n        <div class=\"winnersListingEntryPlayerOverlay js-youtube-close\"></div>\n        <div class=\"winnersListingEntryPlayerSpace\">\n            <iframe class=\"winners-detail-video\" width=\"560\" height=\"315\" src=\""
    + alias4(((helper = (helper = helpers.video || (depth0 != null ? depth0.video : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"video","hash":{},"data":data}) : helper)))
    + "?autohide=1\" frameborder=\"0\" allow=\"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>\n        </div>\n    </div>\n</section>";
},"useData":true}));

this["JST"]["faqcards"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "        <div class=\"col-sm-12 col-md-4 qaCard\">\n            <div class=\"qaCardInner\" index=\""
    + container.escapeExpression(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\">\n                <h3 class=\"title\">"
    + ((stack1 = ((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h3>\n                <div class=\"backgroundElement\"></div>\n                <div class=\"hidden innerSwap\">\n                    "
    + ((stack1 = ((helper = (helper = helpers.answer || (depth0 != null ? depth0.answer : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"answer","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row-wrap expanderGrid\">\n  <div class=\"container\">\n  \n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  \n  \n  \n  <div class=\"row detailRow\">\n    <div class=\"expanderContent\"></div>\n    <div class=\"expanderNav\">\n      <span class=\"faqNavPrev materialIcons\">keyboard_arrow_left</span>\n      <span class=\"counter\">\n        <span class=\"index\"></span>\n        <span class=\"txt\">of</span>\n        <span class=\"total\"></span>\n      </span>\n      <span class=\"faqNavNext materialIcons\">keyboard_arrow_right</span>\n      <span class=\"faqNavClose materialIcons\">close</span>\n    </div>\n  </div>\n  </div>\n</div>";
},"useData":true});

this["JST"]["footer"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"footerContent\">\n    <div class=\"container\">\n        <div class=\"row\">\n            <div class=\"col-xs-12\">\n                <div class=\"footerSocialLinks\">\n                    <a href=\"https://www.facebook.com/dhsscitech\" class=\"footerSocialLinksItem visible-xs-inline\" target=\"_blank\"><img src=\"../img/social-facebook.png\" data-social=\"fb\" class=\"socialLinksIcon\" height=\"16\" alt=\"Facebook\"/></a>\n                    <a href=\"https://www.twitter.com/dhsscitech\" class=\"footerSocialLinksItem visible-xs-inline\" target=\"_blank\"><img src=\"../img/social-twitter.png\" data-social=\"tw\" class=\"socialLinksIcon\" height=\"12\" alt=\"Twitter\"/></a>\n                    <a href=\"https://www.linkedin.com/company/dhsscitech\" class=\"footerSocialLinksItem visible-xs-inline\" target=\"_blank\"><img src=\"../img/social-linkedin.png\" data-social=\"li\" class=\"socialLinksIcon\" height=\"18\" alt=\"Linkedin\"/></a>\n                </div>\n                <div class=\"footerLegalLinks\">\n                    <a href=\"#privacy-policy\" class=\"legalLink\">Privacy Policy</a>\n                    <a href=\"#terms-conditions\" class=\"legalLink\">Terms &amp; Conditions</a>\n                    <a href=\"mailto:questions@respiratorchallenge.org\" class=\"legalLink\">Contact</a>\n                </div>\n                <div class=\"footerCopyright\">\n                    <p class=\"footerCopyrightText\">Challenge designed by <a href=\"https://www.thecommonpool.org\" target=\"_blank\" class=\"copyrightLink\">Common&nbsp;Pool</a>; powered by <a href=\"https://www.rampit.com\" target=\"_blank\" class=\"copyrightLink\">Rampit</a>. &copy;&nbsp;2018&nbsp;Rampit.</p>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["judgespage"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgegroup,depth0,{"name":"judgegroup","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgeinfogroup,depth0,{"name":"judgeinfogroup","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"judges\" class=\"container card-container judgesCardContainer\">\n    <div class=\"row\">\n        <div class=\"col-xs-12\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.judgeGroups : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n\n\n<div class=\"selectionCommitteeContainer\">\n    <div class=\"container\">\n        <div class=\"row\">\n            <div class=\"col-md-6\">\n\n                <section class=\"judgeGroup\" data-judgegroup=\"Selection Committee\" data-title=\"Selection Committee\">\n                    <div class=\"judgeGroupHeading\">\n                        <h4 class=\"judgeHeadingTitle retired-load_judges_g2_h3\">Selection Committee</h4>\n\n                        <div class=\"judgeGroupDescription retired-load_judges_g2_content\">\n                            <p>Finalists will attend a demonstration period in January 2020 to compete for the Grand Prize at the Department of Homeland Security Science and Technology Directorate. The Selection Committee consists of members of the Department of Homeland Security Science and Technology Directorate team. These team members will be present for the Finalists’ demonstration period and will designate the Winner of the $100,000 award. Determination of the Winner rests with solely with the Selection Committee and the Department of Homeland Security Science and Technology Directorate.<br /></p>\n                        </div>\n\n                    </div>\n                </section>\n\n            </div>\n        </div>\n    </div>\n</div>\n\n<!--\n********************************\nJudge detail animation classes; can combine\njust adjust $judgesDetail-transition var\n\njudgeDetailSlideInRight\njudgeDetailFadeIn\n\n********************************\n-->\n\n<div id=\"judgesDetail\" class=\"judgesDetailContainer judgeDetailFadeIn\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.judgeGroups : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});

this["JST"]["lastcard"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n                    <p class=\"actionTitle\">\n"
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitSubTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "                    </p>\n                    <a href=\"#about\" class=\"btnPrimary btnPrimaryAlternate\">Learn More</a>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                            Only <span class=\"daysLeft\">60</span> <span class=\"dayLeftCountWord\">days</span> to submit\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                            Submissions have closed\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"registered",{"name":"checkUser","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n                        <p class=\"actionTitle\">\n"
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitSubTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "                        </p>\n                        <a href=\"#about\" class=\"btnPrimary btnPrimaryAlternate\" type=\"submit\">Learn More</a>\n\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                                Only <span class=\"daysLeft\">60</span> <span class=\"dayLeftCountWord\">days</span> to submit\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                                Submissions have closed.\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitRegTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "                            <p class=\"actionTitle\">Only <span class=\"daysCount\">64</span>&nbsp;<span class=\"dayCountWord\">days</span> left to Register</p>\n                            <a href=\"#about\" class=\"btnPrimary btnPrimaryAlternate\" type=\"submit\">Learn More</a>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            <p class=\"actionTitle\">\n"
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitSubTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data})) != null ? stack1 : "")
    + "                            </p>\n                            <a href=\"#about\" class=\"btnPrimary btnPrimaryAlternate\" type=\"submit\">Learn More</a>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "                                    Registration has closed.\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "                                    Submissions have closed.\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"container\">\n    <div class=\"row\">\n        <div class=\"col-sm-6 lastCardAction\">\n            <div class=\"lastCardActionArea\">\n\n                <div class=\"retired-load_all_last_card\">\n                    <h3>Help DHS S&amp;T advance solutions for CBR threats</h3>\n                </div>\n\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"loggedin",{"name":"checkUser","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "            </div>\n        </div>\n        <div class=\"col-sm-6 lastCardContent\">\n            <div class=\"lastCardContentArea\">\n                <div class=\"retired-load_home_about_card\">\n                    <h3 class=\"contentTitle\">About DHS S&amp;T</h3>\n                    <p class=\"contentParagraph\">Technology and threats evolve rapidly in today’s ever-changing environment. The Department of Homeland Security (DHS) <a href=\"https://www.dhs.gov/science-and-technology\" target=\"_blank\">Science and Technology Directorate</a> (S&amp;T) monitors those threats and rapidly capitalizes on technological advancements, developing solutions and bridging capability gaps at a pace that mirrors the speed of life.</p>\n                    <a href=\"#about\" class=\"contentLearnMore\">Learn More</a>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});

this["JST"]["navbar"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"navbarBtnWrap hidden-xs\"><a href=\"#rp\" class=\"navbarBtn solid navreg retire-modal-trigger\">Return to Dashboard</a></span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"registered",{"name":"checkUser","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"navbarBtnWrap hidden-xs\"><a href=\"#login\" class=\"navbarBtn solid navreg retire-modal-trigger\">Login</a></span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitRegTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                    <span class=\"navbarBtnWrap hidden-xs\"><a href=\"#register\" class=\"navbarBtn solid navreg retire-modal-trigger\"><span class=\"dayCount\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timeConfig : depth0)) != null ? stack1.rampitRegTimeLeft : stack1), depth0))
    + "</span>&nbsp;<span><span class=\"dayCountWord\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timeConfig : depth0)) != null ? stack1.rampitRegTimeFrame : stack1), depth0))
    + "</span> to <strong>Register</strong></span></a></span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"navbarBtnWrap hidden-xs\"><a href=\"#login\" class=\"navbarBtn solid navreg retire-modal-trigger\">Login</a></span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<nav class=\"navbar\" role=\"navigation\">\n  <div class=\"container-fluid\">\n\n    <div class=\"navbarHeader layout-vertical-center\">\n      <a class=\"navbarBrand anim\" href=\"/#home\"></a>\n    </div>\n\n    <div class=\"navbarMenuWrap\">\n      <div class=\"navbarMenuBtn jsMenuControl\">\n        <span class=\"menuLabel hamburger\">\n          <span class=\"hamburgerTop\"></span>\n          <span class=\"hamburgerMiddle\"></span>\n          <span class=\"hamburgerBottom\"></span>\n        </span>\n      </div>\n      <div class=\"closeWrap closeButton\">\n        <i class=\"materialIcons\">close</i>\n      </div>\n\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"loggedin",{"name":"checkUser","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n      <div class=\"socialShare\">\n        <div class=\"txtWrap\">Share <span class=\"materialIcons\">add</span></div>\n        <div class=\"linksWrap\">\n          <a href=\"#\" target=\"_blank\" class=\"shareLink facebookLink shareSocial\" data-action=\"facebook\" data-title=\"The Escape Respirator Challenge, sponsored by the Department of Homeland Security Science and Technology Directorate, invites you to submit ideas to protect people from aerosolized chemical, biological, and radiological (CBR) hazards.\">\n            <span class=\"socialIcon\">\n              <img src=\"img/social-facebook.png\" alt=\"\">\n            </span>\n          </a>\n          <a href=\"#\" target=\"_blank\" class=\"shareLink shareSocial\" data-action=\"twitter\" data-title=\"www.respiratorchallenge.org: seeking ideas to protect against aerosolized chemical, biological, and radiological #CBRhazards. @dhsscitech\">\n            <span class=\"socialIcon\">\n              <img src=\"img/social-twitter.png\" alt=\"\">\n            </span>\n          </a>\n          <a href=\"#\" target=\"_blank\" class=\"shareLink  shareSocial\" data-action=\"linkedin\" data-title=\"The Escape Respirator Challenge, sponsored by the Department of Homeland Security Science and Technology Directorate, invites you to submit ideas to protect people from aerosolized chemical, biological, and radiological (CBR) hazards.\">\n            <span class=\"socialIcon\">\n              <img src=\"img/social-linkedin.png\" alt=\"\">\n            </span>\n          </a>\n        </div>\n      </div>\n    </div>\n\n  </div>\n</nav>";
},"useData":true});

this["JST"]["newsDetailsModal"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <a href=\"#news/"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"newsModalBtnNav navPrev\">\n                <span class=\"newsModalBtnNavInner materialIcons\">keyboard_arrow_left</span>\n            </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <a href=\"#news/"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.nextItem : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"newsModalBtnNav navNext\">\n                <span class=\"newsModalBtnNavInner materialIcons\">keyboard_arrow_right</span>\n            </a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4=container.lambda;

  return "<div class=\"newsModal\">\n\n    <div class=\"newsModalNav\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <span class=\"newsNavCount\">"
    + alias3((helpers.formatNumber || (depth0 && depth0.formatNumber) || alias2).call(alias1,(helpers.plusOne || (depth0 && depth0.plusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.originalIndex : stack1),{"name":"plusOne","hash":{},"data":data}),{"name":"formatNumber","hash":{},"data":data}))
    + " <span class=\"newsNavCountOf\">of</span> "
    + alias3((helpers.formatNumber || (depth0 && depth0.formatNumber) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.records : depth0)) != null ? stack1.length : stack1),{"name":"formatNumber","hash":{},"data":data}))
    + "</span>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <div class=\"newsModalCloseWrap\">\n            <div class=\"newsModalCloseBtn modalClose\">\n                <span class=\"newsModalCloseBtnInner materialIcons\">close</span>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"newsModalCard\">\n\n        <div class=\"innerScrollable\">\n\n\n            <div class=\"newsModalContentContainer\">\n                <div class=\"container\">\n                    <div class=\"row\">\n                        <div class=\"col-md-12\">\n                            <div class=\"newsModalContent\">\n                                <span class=\"newsItemDate\">"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.date : stack1), depth0))
    + "</span>\n                                <h4 class=\"newsItemTitle\">"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.title : stack1), depth0))
    + "</h4>\n                                <div class=\"content\">\n                                    <p>"
    + ((stack1 = alias4(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.content : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n                                </div>\n                            </div>\n                        </div>\n\n                    </div>\n\n                    <div class=\"row\">\n                        <div class=\"col-sm-12\">\n                            <div class=\"newsModalShare\">\n                                <span class=\"icon-icon-facebook shareSocial\" data-action=\"facebook\" data-title=\""
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.title : stack1), depth0))
    + "\"></span>\n                                <span class=\"icon-icon-twitter shareSocial\" data-action=\"twitter\" data-title=\""
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.title : stack1), depth0))
    + "\"></span>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n\n            </div>\n        </div>\n    </div>\n\n</div>\n";
},"useData":true});

this["JST"]["newspage"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.newslistingentry,depth0,{"name":"newslistingentry","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.newspromocarousel,depth0,{"name":"newspromocarousel","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div class=\"container newsListingWrap\">\n\n    <div class=\"newsListing\">\n\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    </div>\n</div>\n\n<div class=\"newsDetailContainer\"></div>";
},"usePartial":true,"useData":true});

this["JST"]["newspost"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"newsinfoWrap border-box\" data-news=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-pagenav=\"true\">\n    <div class=\"row news-detail\">\n        <div class=\"col-md-5\">\n            <div class=\"newsImageWrapLarge\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + ");\"></div>\n        </div>\n        <div class=\"col-md-6 inner-scrollable\">\n            <span>"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</span>\n            <h3 class=\"name\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>\n            <h4>"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</h4>\n            <p>"
    + ((stack1 = ((helper = (helper = helpers.longDescription || (depth0 != null ? depth0.longDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"longDescription","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n        </div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["portraitmask"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"portraitmask\">\n    <div class=\"bgShapeContainer\">\n        <div class=\"lineShapeOne\" data-top-bottom=\"transform:translateY(100px);\" data-bottom-top=\"transform:translateY(-100px);\">\n            <img src=\"img/parallax/lines.png\" alt=\"parallax lines\" />\n        </div>\n    </div>\n    <img src=\"img/logo-dark.png\" class=\"portraitMaskLogo\"/>\n    <div class=\"mask-content\">\n        <p>This website was designed to be experienced in <span class=\"visible-landscape-mode\">portrait</span><span class=\"visible-portrait-mode\">landscape</span> mode. We apologize for any inconvenience this may cause!</p>\n    </div>\n</div>\n";
},"useData":true});

this["JST"]["processslidecard"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "                <div class=\"swiper-slide\">\n                    <section class=\"swiper-slide-inner\">\n                        <div class=\"textContainer\">\n                            <h3 class=\"title\">"
    + container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n                            <div class=\"description\">"
    + ((stack1 = ((helper = (helper = helpers.content || (depth0 != null ? depth0.content : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.btnText : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </div>\n                        <div class=\"imgContainer\" style=\"background-image:url("
    + ((stack1 = ((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + ");\"></div>\n                    </section>\n                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "                                <a href=\""
    + ((stack1 = ((helper = (helper = helpers.btnLink || (depth0 != null ? depth0.btnLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnLink","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\" class=\"processSlideBtn\">"
    + ((stack1 = ((helper = (helper = helpers.btnText || (depth0 != null ? depth0.btnText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnText","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"swiperContainerOuter swiperMain\">\n    <div class=\"swiper-container\">\n        <div class=\"swiper-wrapper\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.steps : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div class=\"processSwiperNavigate\">\n        <a class=\"processSwiperNavigatePrev\" href=\"#processCarousel\" data-slide=\"prev\"></a>\n        <a class=\"processSwiperNavigateNext\" href=\"#processCarousel\" data-slide=\"next\"></a>\n        <div class=\"processSwiperNavigateNum\"></div>\n        <div class=\"processSwiperNavigateBg\"></div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["scoring"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                <div class=\"sliderTick\" style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\">"
    + alias3(((helper = (helper = helpers.score || (depth0 != null ? depth0.score : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"score","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<section class=\"scoreEntry\">\n    <h3 class=\"scoreTitle\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + " <span class=\"scoreRange\">(0-"
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + ")</span></h3>\n    <div class=\"scoreHeading\">"
    + alias4(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper)))
    + "</div>\n    <div class=\"scoreDescription\"></div>\n    <div class=\"scoreValue\">\n        <div class=\"scoreValueSpace\">\n            <span class=\"valueNumber\" status=\""
    + alias4(((helper = (helper = helpers.statusScore || (depth0 != null ? depth0.statusScore : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"statusScore","hash":{},"data":data}) : helper)))
    + "\"></span>\n            <span class=\"valueBorder valueBorderLeft\"></span>\n            <span class=\"valueBorder valueBorderRight\"></span>\n        </div>\n    </div>\n    <div class=\"scoreSliderWrap\">\n        <div class=\"scoreSlider\">\n            <input id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" class=\"scoreSliderInput\" data-slider-id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "Slider\" type=\"text\" data-slider-min=\"0\" data-slider-max=\""
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + "\" data-slider-step=\"0.1\" data-slider-value=\""
    + alias4(((helper = (helper = helpers.initScore || (depth0 != null ? depth0.initScore : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"initScore","hash":{},"data":data}) : helper)))
    + "\"/>\n        </div>\n        <div class=\"sliderTicks\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"sliderAnchor sliderAnchorLow\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</div>\n        <div class=\"sliderAnchor sliderAnchorHigh\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</div>\n    </div>\n</section>\n";
},"useData":true,"useDepths":true});

this["JST"]["scoringTemplateIntro"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "";
},"useData":true});

this["JST"]["sidebar"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.sidebaritem,depth0,{"name":"sidebaritem","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "          <span class=\"navbarBtnWrap\"><a href=\"#rp\" class=\"btnPrimary btnPrimaryAlternate solid navreg retire-modal-trigger\">Return to Dashboard</a></span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"registered",{"name":"checkUser","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return "              <span class=\"navbarBtnWrap\"><a href=\"#login\" class=\"btnPrimary btnPrimaryAlternate solid navreg retire-modal-trigger\">Login</a></span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitRegTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                  <span class=\"navbarBtnWrap\"><a href=\"#register\" class=\"btnPrimary btnPrimaryAlternate solid navreg retire-modal-trigger\"><span class=\"dayCount\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timeConfig : depth0)) != null ? stack1.rampitRegTimeLeft : stack1), depth0))
    + "</span>&nbsp;<span><span class=\"dayCountWord\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timeConfig : depth0)) != null ? stack1.rampitRegTimeFrame : stack1), depth0))
    + "</span> to Register</span></a></span>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                  <span class=\"navbarBtnWrap\"><a href=\"#login\" class=\"btnPrimary btnPrimaryAlternate solid navreg retire-modal-trigger\">Login</a></span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"sidebarInnerWrap\">\n\n  <div class=\"closeWrap closeButton\">\n    <i class=\"materialIcons\">close</i>\n  </div>\n\n  <div class=\"sidebarItemContainer\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.menuItems : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n\n  <div class=\"mobile-info\">\n\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(alias1,"loggedin",{"name":"checkUser","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"socialShare\">\n      <a href=\"https://www.facebook.com/dhsscitech\" target=\"_blank\" class=\"shareLink facebookLink\">\n        <span class=\"socialIcon\">\n          <img src=\"img/social-facebook.png\" alt=\"\">\n        </span>\n      </a>\n      <a href=\"https://www.twitter.com/dhsscitech\" target=\"_blank\" class=\"shareLink\">\n        <span class=\"socialIcon\">\n          <img src=\"img/social-twitter.png\" alt=\"\">\n        </span>\n      </a>\n      <a href=\"https://www.linkedin.com/company/dhsscitech\" target=\"_blank\" class=\"shareLink\">\n        <span class=\"socialIcon\">\n          <img src=\"img/social-linkedin.png\" alt=\"\">\n        </span>\n      </a>\n    </div>\n  </div>\n\n  <div class=\"sidebarLegalLinks\">\n      <a href=\"#privacy-policy\" class=\"legalLink\">Privacy Policy</a>\n      <a href=\"#terms-conditions\" class=\"legalLink\">Terms &amp; Conditions</a>\n      <a href=\"mailto:questions@respiratorchallenge.org\" class=\"legalLink\">Contact us</a>\n  </div>\n\n</div>\n";
},"usePartial":true,"useData":true});

this["JST"]["social"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "";
},"useData":true});

this["JST"]["timeline"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <div class=\"timelinePointWrapper "
    + ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(data && data.last),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-periodname=\""
    + alias4(((helper = (helper = helpers.period || (depth0 != null ? depth0.period : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"period","hash":{},"data":data}) : helper)))
    + "\" style=\"top:"
    + alias4(((helper = (helper = helpers.offsetPercentage || (depth0 != null ? depth0.offsetPercentage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"offsetPercentage","hash":{},"data":data}) : helper)))
    + "%;\">\n          <div class=\"timelinePoint "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.selected : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"></div>\n          <div class=\"timelineConnectLine "
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || alias2).call(alias1,(depth0 != null ? depth0.sizePx : depth0),"<=",0,{"name":"ifComp","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" style=\"height: "
    + alias4(((helper = (helper = helpers.sizePx || (depth0 != null ? depth0.sizePx : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sizePx","hash":{},"data":data}) : helper)))
    + "px\"></div>\n          <div class=\"timelineBoxWrapper\">\n            <div class=\"timelineBox\">\n              <span class=\"timelineBoxArrow\"></span>\n              <h3 class=\"timelineBoxTitle\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n              <h6 class=\"timelineBoxSubtitle\">"
    + alias4(((helper = (helper = helpers.subtitle || (depth0 != null ? depth0.subtitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subtitle","hash":{},"data":data}) : helper)))
    + "</h6>\n              <div class=\"timelineBoxDetails\">\n                "
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "              </div>\n            </div>\n          </div>\n          \n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "first";
},"4":function(container,depth0,helpers,partials,data) {
    return "last";
},"6":function(container,depth0,helpers,partials,data) {
    return "selected";
},"8":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "                  <div class=\"timelineBoxBtnWrap\">\n                    <a class=\"timelineBoxBtn btnPrimary btnPrimaryAlternate\" href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link","hash":{},"data":data}) : helper)))
    + "\">"
    + ((stack1 = ((helper = (helper = helpers.btntxt || (depth0 != null ? depth0.btntxt : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btntxt","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</a>\n                  </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <div class=\"timelinePeriod\" style=\"top: "
    + alias4(((helper = (helper = helpers.offsetPercentage || (depth0 != null ? depth0.offsetPercentage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"offsetPercentage","hash":{},"data":data}) : helper)))
    + "%; height:"
    + alias4(((helper = (helper = helpers.sizePercentage || (depth0 != null ? depth0.sizePercentage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sizePercentage","hash":{},"data":data}) : helper)))
    + "%\" data-periodname=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n        <div class=\"timelinePeriodDetailsWrap container\">\n          <div class=\"timelinePeriodDetails\">\n            <h3 class=\"timelinePeriodName\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>\n            <h5 class=\"timelinePeriodLength\">"
    + alias4(((helper = (helper = helpers.periodText || (depth0 != null ? depth0.periodText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"periodText","hash":{},"data":data}) : helper)))
    + "</h5>\n          </div>\n        </div>\n      </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"timelineContainer\">\n\n    <div class=\"bgShapeContainer\">\n        <div class=\"lineShapeOne\" data-top-bottom=\"transform:translateY(300px);\" data-bottom-top=\"transform:translateY(-300px);\">\n            <img src=\"img/parallax/lines.png\" alt=\"parallax lines\" />\n        </div>\n        <div class=\"lineShapeTwo\" data-top-bottom=\"transform:translateY(-220px);\" data-bottom-top=\"transform:translateY(-300px);\">\n            <img src=\"img/parallax/lines.png\" alt=\"parallax lines\" />\n        </div>\n    </div>\n\n  <div class=\"timelineContainerInner\">\n\n    <div class=\"timelinePointsOuterWrapper container\">\n      <div class=\"timelineCurrentMoment\" style=\"top:"
    + container.escapeExpression(((helper = (helper = helpers.currentMommentOffsetPercentage || (depth0 != null ? depth0.currentMommentOffsetPercentage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"currentMommentOffsetPercentage","hash":{},"data":data}) : helper)))
    + "%;\"></div>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.timelinePoints : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.timelinePeriods : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <!--<span class=\"timelineNavigation timelineNavigation--hidden\"-->\n          <!--data-anchor-target=\".timelineContainer\"-->\n          <!--data--180-top=\"position: fixed; top: 45vh;\"-->\n          <!--data-start=\"position:absolute; top: 45vh;\"-->\n          <!--data-end=\"top: -80vh;\"-->\n          <!--data-bottom=\"top: 45vh;\">-->\n\n      <span class=\"timelineNavigation timelineNavigation--hidden\">\n\n      <span class=\"timelineNavigationBtn prev\">\n        <span class=\"timelineNavigationBtnInner materialIcons\">keyboard_arrow_up</span>\n      </span>\n      <span class=\"timelineNavigationBtn next\">\n        <span class=\"timelineNavigationBtnInner materialIcons\">keyboard_arrow_down</span>\n      </span>\n    </span>\n  </div>\n</div>";
},"useData":true});

this["JST"]["winnersDetailsModal"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"modal-winners\" data-index=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.index : stack1), depth0))
    + "\">\n    <div class=\"cardWrapper\">\n\n        <div class=\"cardWrap\">\n            <div class=\"inner-scrollable\">\n                <div class=\"container\">\n                    <div class=\"modal-nav-wrap\">\n                        <div class=\"modal-nav-controls-wrap\">\n                            <a href=\"#winner/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"prev\"><span><img src=\"img/arrows.png\" alt=\"\"/></span></a>\n                            <span class=\"num\"><strong>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.index : stack1), depth0))
    + "</strong> of <strong>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.len : stack1), depth0))
    + "</strong></span>\n                            <a href=\"#winner/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.nextItem : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"next\"><span><img src=\"img/arrows.png\" alt=\"\"/></span></a>\n                            <div class=\"modal-close-wrap\"><span class=\"modal-close\"><img src=\"img/closeBtn.png\" alt=\"\"/></span></div>\n                        </div>\n                    </div>\n                    <section class=\"detail\">\n                        <div class=\"row\">\n                            <div class=\"col-12\">\n                                <div class=\"modal-content-container\">\n                                    <div class=\"content-container\">\n                                        <p class=\"winners-item-team\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.team : stack1), depth0))
    + "</p>\n                                        <h4 class=\"winners-item-title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.title : stack1), depth0))
    + "</h4>\n                                        <div class=\"imageWrapLargeInner\">\n                                            <iframe class=\"winners-detail-video\" width=\"560\" height=\"315\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.video : stack1), depth0))
    + "?autohide=1\" frameborder=\"0\" allow=\"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>\n                                        </div>\n                                        <div class=\"content\">\n                                            "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.description : stack1), depth0)) != null ? stack1 : "")
    + "\n                                        </div>\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                    </section>\n                </div>\n            </div>\n        </div>\n\n    </div>\n</div>";
},"useData":true});

this["JST"]["winnerspage"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.winnerslistingentry,depth0,{"name":"winnerslistingentry","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"container\">\n    <div class=\"row\">\n        <div class=\"winnersListing\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n\n\n<div class=\"winnersDetailContainer\"></div>";
},"usePartial":true,"useData":true});

this["JST"]["winnerspost"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"winnersinfoWrap border-box\" data-winners=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-pagenav=\"true\">\n    <div class=\"row winners-detail\">\n        <div class=\"col-md-5\">\n            <div class=\"winnersImageWrapLarge\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + ");\"></div>\n        </div>\n        <div class=\"col-md-6 inner-scrollable\">\n            <span>"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</span>\n            <h3 class=\"name\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>\n            <h4>"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</h4>\n            <p>"
    + ((stack1 = ((helper = (helper = helpers.longDescription || (depth0 != null ? depth0.longDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"longDescription","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n        </div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["404"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"missingPageInner\">\n    <div class=\"container\">\n        <div class=\"row layout-vertical-center\">\n        	<div class=\"col-sm-12 col-md-offset-1 col-md-10 text-center\">\n				<div class=\"retired-load_404_card1 layout-load-card\">\n                    <h1><span>404 Error</span></h1>\n		            <p>The page you are looking for is no longer here, or maybe it never was there in the first place. In any case, we’re sorry you were sent on this mission far from home.</p>\n                </div>\n				<a href=\"#home\" class=\"btnPrimary btnPrimaryAlternate\">Take Me Home</a>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});

if (typeof exports === 'object' && exports) {module.exports = this["JST"];}

return this["JST"];

});
