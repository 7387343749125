define('modules/news/promogrid/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "jquery.mobile.custom"], function (module, config, $, util, templates) {
    return new module({
        name: 'news',
        selectors: '.newsPage',
        remoteData: [config.urls.news],

        routes: [{
            route: 'news/(\\d+)',
            fn: function (hash) {
                var newsId = hash.split('/')[1];
                this.renderNewsDetails(newsId);
            },
            extra: true,
            delay: 1000
        }, {
            route: 'news',
            fn: function(){
                if ($('.newsDetailContainer').hasClass('newsDetailsOpened')) {
                    this.closeDetails();
                }
            },
            extra: true,
            delay: 500
        }],

        config: {
            promoCount: 3,
            useCarousel: true,
            showPromoItemsInListing: false
        },

        initialize: function (data) {
            this.$container = $(this.getSelectors());

            this.data = data;
            var shouldUseCarousel = this.getConfig().useCarousel || $(window).width() < 768;
            var promoNewsItems = data.slice(0, this.getConfig().promoCount);

            var regularNewsItems = (this.getConfig().showPromoItemsInListing ? data : data.slice(this.getConfig().promoCount));

            var categories = util.unique($.map(regularNewsItems, function (record) {
                return record.category;
            }));
            categories.unshift('All Categories');

            this.$container.find('.news').html(templates.newspage({
                regularNewsItems: regularNewsItems,
                promoNewsItems: promoNewsItems,
                records: regularNewsItems,
                categories: $.map(categories, function (name) {
                    return {name: name};
                }),
                usecarousel: shouldUseCarousel
            }));

            if (data.length <= this.getConfig().promoCount) {
                // this.$container.find('.newsListing').remove();
            } else if (categories.length > 2) {
                this.$container.find('.newsListingTabs .newsListingTab').on('click', $.proxy(function (e) {
                    e.preventDefault();
                    $(e.currentTarget).siblings().removeClass('active');
                    $(e.currentTarget).addClass('active');
                    this.switchPostListing($(e.currentTarget).text());
                }, this));
                this.$container.find('.newsListingTabs .newsListingTab').first().trigger('click');
            } else {
                this.$container.find('.newsListingTabs').remove();
            }

            if (shouldUseCarousel) {
                // init carousel
                this.$container.find('.carousel.slide')
                    .carousel({
                        interval: 7500,
                        pause: null
                    }).swipeleft(function () {
                    $(this).carousel('next');
                }).swiperight(function () {
                    $(this).carousel('prev');
                });
            }

            this.attachEventHandlers();
        },

        onRender: function () {
            if (this['defferedRenderNewsDetail']) {
                this.renderNewsDetails(this['defferedRenderNewsDetail']);
            }
        },

        methods: {

            switchPostListing: function (category) {
                if (category == 'All Categories') {
                    this.$container.find('.newsListingEntry').show();
                } else {
                    this.$container.find('.newsListingEntry').hide();
                    this.$container.find('.newsListingEntry[data-category="' + category + '"]').show();
                }
            },

            renderNewsDetails: function (newsId) {
                if (!this.isReady()) {
                    this.defferedRenderNewsDetail = newsId;
                    return;
                }

                if (!this.newsById) {
                    var newsById = {};
                    $.each(this.data, $.proxy(function (index, item) {
                        item.originalIndex = index;
                        newsById[item.id] = item;
                    }, this));
                    this.newsById = newsById;
                }

                var newsItem = this.newsById[newsId];
                // TODO: think about the possibility to have 1 or 2 news items of the same category
                if (newsItem) {
                    var i = 0, currentItem;
                    if (!newsItem.prevItem) {
                        for (i = newsItem.originalIndex - 1; i >= 0; i--) {
                            currentItem = this.data[i];
                            if (newsItem.category == currentItem.category) {
                                newsItem.prevItem = currentItem;
                                break;
                            }
                        }
                        if (!newsItem.prevItem) {
                            for (i = this.data.length - 1; i > newsItem.originalIndex; i--) {
                                currentItem = this.data[i];
                                if (newsItem.category == currentItem.category) {
                                    newsItem.prevItem = currentItem;
                                    break;
                                }
                            }
                        }
                    }
                    if (!newsItem.nextItem) {
                        for (i = newsItem.originalIndex + 1; i < this.data.length; i++) {
                            currentItem = this.data[i];
                            if (newsItem.category == currentItem.category) {
                                newsItem.nextItem = currentItem;
                                break;
                            }
                        }
                        if (!newsItem.nextItem) {
                            for (i = 0; i < newsItem.originalIndex; i++) {
                                currentItem = this.data[i];
                                if (newsItem.category == currentItem.category) {
                                    newsItem.nextItem = currentItem;
                                    break;
                                }
                            }
                        }
                    }

                    this.$container.closest('body').addClass('locked newsModalOpen');
                    this.$container.closest('body').find('.navbarWrap').addClass('hidden').hide();

                    this.$container.find('.newsDetailContainer').html(templates.newsDetailsModal({
                        item: newsItem,
                        records: this.data
                    }));

                    this.$container.find('.carousel.slide').carousel('pause');

                    setTimeout($.proxy(function () {
                        this.$container.find('.newsDetailContainer').addClass('newsDetailsOpened');
                    }, this), 1000);
                }

            },

            closeDetails: function () {
                if (this.isReady()) {
                    var newsDetailContainer = $('.newsDetailContainer');
                    $('body').removeClass('locked newsModalOpen');
                    newsDetailContainer.removeClass('newsDetailsOpened');
                    setTimeout($.proxy(function () {
                        this.$container.find('.newsDetailContainer').empty();
                    }, this), 1000);
                    $('.navbarWrap').removeClass('hidden').show();
                    this.$container.find('.carousel.slide').carousel('cycle');
                }
            },

            attachEventHandlers: function () {
                this.$container.on("click", ".newsDetailContainer .modalClose", $.proxy(function (e) {
                    e.preventDefault();
                    window.history.pushState('news', 'News', '#news');
                    this.closeDetails();
                }, this));
            }
        }

    });
});
