define('hooks',['jquery', 'app/util'], function ($, util) {
    var methods = {
        onDocumentReady: function () {
            //>>excludeStart("production",pragmas.production);
            console.debug('Project hook - document ready.')
            //>>excludeEnd("production");
        },
        onAppInitialized: function () {
            //>>excludeStart("production",pragmas.production);
            console.debug('Project hook - app initialized.')
            //>>excludeEnd("production");

            $('.navbarMenuWrap .closeButton').on('click', function () {
                $('body').removeClass('menuOpened');
                $('.socialShare').removeClass('socialExpanded');
            });

            $('[data-scrollto]').each(function () {
                $(this).off('click').on('click', function () {
                    $('html, body').animate({
                        scrollTop: $($(this).data('scrollto')).offset().top
                    }, $($(this).data('scrollto')).offset().top);
                    $(this).blur();
                    return false;
                });
            });

            var hash = window.location.hash,
                html = $('html'),
                finalists = $('.finalistsAnnouncement');

            if (hash === '#home' || hash === '') {
                html.addClass('finalistsAnnouncementOpen');
            }

            finalists.each(function () {
                var self = $(this);
                $('.btnPrimaryAlternate, .finalistsAnnouncementContinue', self).on('click', function () {
                    self.addClass('finalistsAnnouncementHide');
                    setTimeout(function () {
                        html.removeClass('finalistsAnnouncementOpen');
                    }, 400);
                });
            });

            /*
            * Resizes topcards on mobile/tablets to 100% of viewport height instead of 100vh
            */
            (function () {
                function doResize() {
                    if (win.width() <= 1024) {
                        topcards.css('height', html.height());
                    } else {
                        topcards.removeAttr('style');
                    }
                }

                function wait() {
                    setTimeout(function () {
                        if ($('.newsPromoCarousel').length === 0) {
                            wait();
                        } else {
                            topcards = $('.newsPromoCarousel');
                            win.trigger('resize');
                            topcards = $('.homeTopCard,.newsPromoCarousel');
                        }
                    }, 500);
                }

                var win = $(window),
                    html = $('html'),
                    topcards = $('.homeTopCard');
                win.on('resize', doResize);
                win.on('resizeWait', wait);
                wait();
            })();

            // array.find ie support
            if (typeof Array.prototype.find === 'undefined') {

                Array.prototype.find = function (callback, thisArg) {
                    for (var i = 0; i < this.length; i++) {
                        if (callback.call(thisArg || window, this[i], i, this))
                            return this[i];
                    }
                    return undefined;
                };
            }
        }
    };
    $(document).ready(methods.onDocumentReady);

    return methods;
});

